/* eslint-disable no-console */
import { create } from "zustand";
import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import {
  FieldComponent,
  OptionsBasedField,
  BaseFieldComponentError,
  KatKinProduct,
  OptionType,
  FlowComponent,
  SectionComponent,
  RepeatableField,
  FieldValue,
  SubmitFieldData,
  InterpolationBehavior,
  TransformedDisplayValue,
  TransformedDisplayValueResponse,
  FieldName,
  TransformableField,
  ITransformableFieldData,
  FieldMap,
  AdditionalFieldData,
  AddressFieldValue,
  FieldsetComponent,
  FieldsetMap,
  FieldsetData,
} from "@forms/schema";
import dayjs, { Dayjs } from "dayjs";
import Address from "libs/address/src/lib/address";
import { arrayToSentence } from "libs/string-utils/src/lib/string-utils";
import { isValidPhoneNumber } from "libphonenumber-js";
import { persist } from "zustand/middleware";
import {
  calculateCatWeight,
  calculateSuggestedCatTraySize,
} from "libs/cat-calculations/src/lib/cat-calculations";
import {
  BodyType,
  Breed,
  Gender,
  ICatAge,
  LifeStyle,
} from "libs/cat-calculations/src/lib/cat-calculations.types";
import {
  BREEDS,
} from "libs/cat-calculations/src/lib/cat-calculations.constants";
import {
  isFieldset,
} from "apps/forms-structure/src/app/utils/forms-schema-type-guards";
import { ICatExtraDetails } from "libs/api/customer/src/lib/types/cats";
import { Logger } from "@datadog/browser-logs/cjs/domain/logger";

type ValidationResponseCode = "SUCCESS" | "FAILURE" | "ERROR";
interface ValidationResponse {
  response: ValidationResponseCode;
  errorMessage?: string;
  subFieldName?: string;
}

const { postcodeCheck } = Address();

export const getRepeatedFieldsArray = (
  flowId: string,
  fieldToRepeatFrom: RepeatableField | undefined,
): string[] => {
  // This method checks if we have a value in the store for the fieldToRepeatFrom
  // If we do and it's a number, then we create an array of that length
  // If we do and it's an array, then we return the submit values of the array as the keys
  if (!fieldToRepeatFrom) {
    return [ "0" ];
  }
  let value = getFieldValue(flowId, fieldToRepeatFrom);
  if (fieldToRepeatFrom === "numberOfCats" && !value) {
    value = getFieldValue(flowId, "catIds");
  }
  if (!value) {
    return [ "0" ];
  }

  if (value.data?.submitValue) {
    if (Array.isArray(value.data.submitValue)) {
      if (value.data.submitValue.length > 0) {
        const returnVal = value.data.submitValue.map((val) => `${val}`);
        // This is so that when a repeatable value is deselected, the relevant linking ID is also removed
        // So, for example, if you have cats with IDs [X, Y, Z] selected, and you deselect cat Y,
        // this will delete all values related to cat Y
        deleteFieldsWithoutLinkingId(flowId, returnVal);
        return returnVal;
      }
      return [ "0" ];
    }
    const numberValue = +(value.data.submitValue || 1) || 1;
    if (numberValue > 0) {
      const arr = [ ...Array(numberValue) ].map((val, idx) => `${idx + 1}`);
      deleteFieldsWithoutLinkingId(flowId, arr);
      return arr;
    }
  }

  return [ "0" ];
};

export const deleteFieldsWithoutLinkingId = (flowId: string, linkingIds: string[]) => {
  const flow = useNewFormsServiceStore.getState().flows.find((f) => f.id === flowId);
  const fieldsToBeDeletedFromStore = flow?.fields?.filter((f) => f.key.linkingId !== "0" && !linkingIds.some((li) => li === f.key.linkingId));
  fieldsToBeDeletedFromStore?.forEach((field) => useNewFormsServiceStore.getState()
    .removeFlowField(flowId, field.key.fieldName, field.key.linkingId));
};

export const getCurrentFlow = (slug: string) => useNewFormsServiceStore.getState()
  ?.flows.find((flow) => flow.id === slug);

export const getCurrentSection = (flow: FlowComponent, formSlug: string, sectionSlug: string) => {
  const sectionToReturn = flow.forms
    .find((form) => form.slug === formSlug)?.content.sections.find((section) => section.slug === sectionSlug);
  return sectionToReturn;
};

export const getFieldValue = (flowId: string, fieldName: string, linkingId?: string) => {
  const flow = useNewFormsServiceStore.getState().flows.find((f) => f.id === flowId);
  if (!linkingId) {
    return flow?.fields?.find((f) => f.key.fieldName === fieldName);
  }
  return flow?.fields?.find((f) => f.key.fieldName === fieldName && f.key.linkingId === linkingId);
};

export const isSingleCat = (flowId: string) => {
  const flow = useNewFormsServiceStore.getState().flows.find((f) => f.id === flowId);
  const numberOfCats = flow?.fields?.find((f) => f.key.fieldName === "numberOfCats")?.data?.submitValue as number;
  return numberOfCats === 1 || numberOfCats - (flow?.fields?.filter((f) => f.key.fieldName === "catPlanRemoved")?.length ?? 0) === 1;
};

export const getFieldsetState = (flowId: string, fieldsetId: string, linkingId?: string) => {
  const flow = useNewFormsServiceStore.getState().flows.find((f) => f.id === flowId);
  if (!linkingId) {
    return flow?.fieldsets?.find((f) => f.key.fieldsetId === fieldsetId);
  }
  return flow?.fieldsets?.find((f) => f.key.fieldsetId === fieldsetId && f.key.linkingId === linkingId);
};

export const getFieldValues = (flowId: string, fieldName: string): FieldMap[] => {
  const flow = useNewFormsServiceStore.getState().flows.find((f) => f.id === flowId);
  return flow?.fields?.filter((f) => f.key.fieldName === fieldName) || [];
};

export type SubmissionFieldValues = Map<FieldName, SubmitFieldData | SubmitFieldData[]>;

export const getFlowFieldValuesForAction = (slug: string) => {
  const flow = getCurrentFlow(slug);
  const fields = flow?.fields;
  const submissionData: SubmissionFieldValues = new Map();
  if (fields) {
    fields.forEach((field) => {
      if (field.key.linkingId && field.data.submitValue) { // Annoying linking values for things like catNames etc....
        if (submissionData.has(field.key.fieldName)) {
          const current = submissionData.get(field.key.fieldName);
          if (current) {
            if (Array.isArray(current)) {
              const newValues = [ ...current, { value: field.data.submitValue, linkingId: field.key.linkingId } ];
              submissionData.set(field.key.fieldName, newValues);
            } else {
              const newValues = [ current, { value: field.data.submitValue, linkingId: field.key.linkingId } ];
              submissionData.set(field.key.fieldName, newValues);
            }
          } else {
            submissionData.set(field.key.fieldName, { value: field.data.submitValue, linkingId: field.key.linkingId });
          }
        } else {
          submissionData.set(field.key.fieldName, { value: field.data.submitValue, linkingId: field.key.linkingId });
        }
      }
    });
  }
  return submissionData;
};

const isSingularData = (fields: FieldMap[], fieldName: FieldName) => {
  const transformableFromData = getFieldData(fields, fieldName);
  return !!(transformableFromData.length === 1 && transformableFromData[0].data?.submitValue);
};

const getAllOrSingularRelatedTransformedString = (
  fields: FieldMap[],
  fieldName: FieldName,
) => (isSingularData(fields, fieldName) ? getFieldData(fields, fieldName)[0].data.submitValue : undefined);

const getCatWeightFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
): string => {
  if (interpolationBehavior !== "GET_RELATED") return "0";

  const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue as Gender;
  const catBreed = getRelatedFieldData(fields, "catBreed", linkingId)?.submitValue as Breed;
  const catAge = {
    type: getRelatedFieldData(fields, "catLifeStage", linkingId)?.submitValue || "Adult",
    years: Number(getRelatedFieldData(fields, "catAgeYears", linkingId)?.submitValue || 0),
    months: Number(getRelatedFieldData(fields, "catAgeMonths", linkingId)?.submitValue || 0),
    weeks: Number(getRelatedFieldData(fields, "catAgeWeeks", linkingId)?.submitValue || 0),
  } as ICatAge;

  if (!catSex || !catBreed || !catAge) return "0";

  return calculateCatWeight(catBreed, catAge, catSex).toFixed(1);

};

const getAbsoluteNumberOfCats = (fields: FieldMap[]): number => {
  const numberOfCats = fields.find((field) => field.key.fieldName === "numberOfCats")?.data?.submitValue ?? fields.filter((field) => field.key.fieldName === "catIds").length;
  return +(numberOfCats ?? 1);
};

const getDoesntDontFromDependencies = (fields: FieldMap[]) => (getAbsoluteNumberOfCats(fields) > 1 ? "don't" : "doesn't");

const getCatCatsFromDependencies = (fields: FieldMap[]) => (getAbsoluteNumberOfCats(fields) > 1 ? "cats" : "cat");

const getCatAgeFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedCatAge = "";
  if (interpolationBehavior === "GET_RELATED") {
    const catAgeYears = getRelatedFieldData(fields, "catAgeYears", linkingId)?.submitValue;
    const catAgeMonths = getRelatedFieldData(fields, "catAgeMonths", linkingId)?.submitValue;
    const catAgeWeeks = getRelatedFieldData(fields, "catAgeWeeks", linkingId)?.submitValue;

    if (catAgeYears && Number(catAgeYears) > 0) {
      transformedCatAge = `${catAgeYears} year${Number(catAgeYears) > 1 ? "s" : ""} old`;
    } else if (catAgeMonths && Number(catAgeMonths) > 0) {
      transformedCatAge = `${catAgeMonths} month${Number(catAgeMonths) > 1 ? "s" : ""} old`;
    } else if (catAgeWeeks && Number(catAgeWeeks) > 0) {
      transformedCatAge = `${catAgeWeeks} week${Number(catAgeWeeks) > 1 ? "s" : ""} old`;
    }
  }
  return transformedCatAge;
};

const getCatPedigreeOrMoggieFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedCatPedigreeOrMoggie = "";
  if (interpolationBehavior === "GET_RELATED") {
    const catBreed = getRelatedFieldData(fields, "catBreed", linkingId)?.submitValue;

    const breed = BREEDS.find((mappedBreed) => mappedBreed.value === catBreed);
    transformedCatPedigreeOrMoggie = breed?.isPedigree ? "Pedigree" : "Moggie";
  }
  return transformedCatPedigreeOrMoggie;
};

const getCatKcalPerDayFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedCatKcalPerDay = "";
  if (interpolationBehavior === "GET_RELATED") {
    const catWeight = Number(getRelatedFieldData(fields, "catWeight", linkingId)?.submitValue);
    const catBodyCondition = getRelatedFieldData(fields, "catBodyCondition", linkingId)?.submitValue as BodyType || "UNKNOWN";
    const catActivity = getRelatedFieldData(fields, "catActivity", linkingId)?.submitValue as LifeStyle || "UNKNOWN";

    const catAgeYears = getRelatedFieldData(fields, "catAgeYears", linkingId)?.submitValue as number || 0;
    const catAgeMonths = getRelatedFieldData(fields, "catAgeMonths", linkingId)?.submitValue as number || 0;
    const catAgeWeeks = getRelatedFieldData(fields, "catAgeWeeks", linkingId)?.submitValue as number || 0;

    if (catWeight && catBodyCondition && catActivity && (catAgeYears || catAgeMonths || catAgeWeeks)) {
      const tray = calculateSuggestedCatTraySize(
        catWeight,
        catBodyCondition,
        catActivity,
        {
          years: catAgeYears,
          months: catAgeMonths,
          weeks: catAgeWeeks,
        },
      );

      transformedCatKcalPerDay = tray.calories ? `${tray.calories} kcal/day` : "";
    }
  }
  return transformedCatKcalPerDay;
};

const getCatSpayedOrNeuteredFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedCatSpayedOrNeutered;
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedCatSpayedOrNeutered = catSex ? catSex === "BOY" ? "neutered" : "spayed" : "spayed or neutered";
  } else {
    transformedCatSpayedOrNeutered = "spayed or neutered";
  }
  return transformedCatSpayedOrNeutered;
};

const getCatPronounHeSheTheyFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounHeSheThey;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "he" : "she" : "they");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounHeSheThey = transform(catSex);
  } else {
    transformedPronounHeSheThey = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounHeSheThey;
};

const getCatPronounIsAreHeSheTheyFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounDoDoesHeSheThey;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "is he" : "is she" : "are they");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounDoDoesHeSheThey = transform(catSex);
  } else {
    transformedPronounDoDoesHeSheThey = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounDoDoesHeSheThey;
};

const getCatPronounHeSheTheyIsAreFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounDoDoesHeSheThey;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "he is" : "she is" : "they are");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounDoDoesHeSheThey = transform(catSex);
  } else {
    transformedPronounDoDoesHeSheThey = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounDoDoesHeSheThey;
};

const getCatPronounHesShesTheyreFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounHesShesTheyre;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "he's" : "she's" : "they're");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounHesShesTheyre = transform(catSex);
  } else {
    transformedPronounHesShesTheyre = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounHesShesTheyre;
};

const getCatPronounIsAreHeSheTheyHasHaveFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounIsAreHeSheTheyHas;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "he has" : "she has" : "they have");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounIsAreHeSheTheyHas = transform(catSex);
  } else {
    transformedPronounIsAreHeSheTheyHas = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounIsAreHeSheTheyHas;
};

const getCatHasHaveFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounHasHave;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field && +(field) > 1 ? "have" : "has");
  if (interpolationBehavior === "GET_RELATED") {
    const numberOfCats = getRelatedFieldData(fields, "numberOfCats", linkingId)?.submitValue;
    transformedPronounHasHave = transform(numberOfCats);
  } else {
    transformedPronounHasHave = transform(getAllOrSingularRelatedTransformedString(fields, "numberOfCats"));
  }
  return transformedPronounHasHave;
};

const getYouAndCatNameFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  const names = [ "You" ];
  if (interpolationBehavior === "GET_RELATED") {
    const catName = getRelatedFieldData(fields, "catName", linkingId)?.submitValue;
    if (typeof catName === "string") {
      names.push(catName);
    }
  } else {
    getFieldData(fields, "catName").forEach((catNameData) => {
      const catName = catNameData.data?.submitValue;
      if (typeof catName === "string") {
        names.push(catName);
      }
    });
  }
  const transformedYouAndCatName = arrayToSentence(names);
  return transformedYouAndCatName;
};

const getCatPronounDoDoesHeSheTheyFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounDoDoesHeSheThey;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "does he" : "does she" : "do they");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounDoDoesHeSheThey = transform(catSex);
  } else {
    transformedPronounDoDoesHeSheThey = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounDoDoesHeSheThey;
};

const getCatPronounHisHerTheirFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounHisHersTheirs;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "his" : "her" : "their");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounHisHersTheirs = transform(catSex);
  } else {
    transformedPronounHisHersTheirs = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounHisHersTheirs;
};
const getCatPronounHimHerThemFromDependencies = (
  fields: FieldMap[],
  linkingId: string,
  interpolationBehavior: InterpolationBehavior,
) => {
  let transformedPronounHisHersTheirs;
  const transform = (field: FieldValue | FieldValue[] | undefined) => (field ? field === "BOY" ? "him" : "her" : "them");
  if (interpolationBehavior === "GET_RELATED") {
    const catSex = getRelatedFieldData(fields, "catSex", linkingId)?.submitValue;
    transformedPronounHisHersTheirs = transform(catSex);
  } else {

    transformedPronounHisHersTheirs = transform(getAllOrSingularRelatedTransformedString(fields, "catSex"));
  }
  return transformedPronounHisHersTheirs;
};

export const getFieldData = (fields: FieldMap[], fieldName: FieldName) => fields.filter(
  (mappedField) => mappedField.key.fieldName === fieldName,
);

export const getRelatedFieldData = (fields: FieldMap[], fieldName: FieldName, linkingId: string) => fields.find(
  (mappedField) => mappedField.key.fieldName === fieldName && mappedField.key.linkingId === linkingId,
)?.data;

export const getTransformedFlowFieldValues = (
  fields: FieldMap[] | undefined,
  linkingId: string | undefined,
  interpolationBehavior: InterpolationBehavior,
): TransformedDisplayValueResponse => {
  if (!fields) return {};
  const getLinkingId = linkingId || "0";
  const transformedDisplayValueResponse: TransformedDisplayValueResponse = {};

  const transformableData: TransformableField = {
    doesntDont: { dependencies: [], callback: getDoesntDontFromDependencies },
    catCats: { dependencies: [], callback: getCatCatsFromDependencies },
    catAge: { dependencies: [], callback: getCatAgeFromDependencies },
    catPredictedWeight: { dependencies: [], callback: getCatWeightFromDependencies },
    catSpayedOrNeutered: { dependencies: [], callback: getCatSpayedOrNeuteredFromDependencies },
    catPronounHeSheThey: { dependencies: [ ], callback: getCatPronounHeSheTheyFromDependencies },
    catPronounHisHerTheir: { dependencies: [ ], callback: getCatPronounHisHerTheirFromDependencies },
    catPronounHimHerThem: { dependencies: [ ], callback: getCatPronounHimHerThemFromDependencies },
    catPronounDoDoesHeSheThey: { dependencies: [ ], callback: getCatPronounDoDoesHeSheTheyFromDependencies },
    catPronounIsAreHeSheThey: { dependencies: [ ], callback: getCatPronounIsAreHeSheTheyFromDependencies },
    catPronounHeSheTheyIsAre: { dependencies: [ ], callback: getCatPronounHeSheTheyIsAreFromDependencies },
    catPronounHesShesTheyre: { dependencies: [ ], callback: getCatPronounHesShesTheyreFromDependencies },
    catPronounHeSheTheyHasHave: { dependencies: [ ], callback: getCatPronounIsAreHeSheTheyHasHaveFromDependencies },
    catPedigreeOrMoggie: { dependencies: [], callback: getCatPedigreeOrMoggieFromDependencies },
    catKcalPerDay: { dependencies: [], callback: getCatKcalPerDayFromDependencies },
    catHasHave: { dependencies: [], callback: getCatHasHaveFromDependencies },
    youAndCatName: { dependencies: [], callback: getYouAndCatNameFromDependencies },
  };

  Object.entries(transformableData).forEach(
    ([ key, transformable ]: [string, ITransformableFieldData]) => {
      let fieldsHasRequiredDependencies = true;
      for (const dependency of transformable.dependencies) {
        // This is get related only
        const hasDependency = !!fields.find(
          (field) => field.key.fieldName === dependency && field.key.linkingId === getLinkingId,
        );
        if (!hasDependency) {
          fieldsHasRequiredDependencies = false;
          break;
        }
      }
      if (fieldsHasRequiredDependencies) {
        transformedDisplayValueResponse[key as TransformedDisplayValue] =
          `${transformable.callback(fields, getLinkingId, interpolationBehavior)}`;
      }
    },
  );
  return transformedDisplayValueResponse;
};

export const getFlowFieldValuesForInterpolation = (slug: string, linkingId: string, interpolationBehavior: InterpolationBehavior = "GET_RELATED") => {
  const flow = getCurrentFlow(slug);
  if (!flow) {
    console.warn("Flow does not exist.", slug);
    return {} as Record<string, string>;
  }
  const fields = flow?.fields;
  const displayData: Record<string, string> = {};
  if (fields) {
    fields.forEach((field) => {
      let data;
      if (interpolationBehavior === "GET_RELATED") {
        const getData = getRelatedFieldData(fields, field.key.fieldName, linkingId)?.displayValue;
        data = getData ? `${getData}` : undefined;
      } else if (interpolationBehavior === "GET_ALL") {
        const linkingIdsToIgnore = flow.fields?.filter((x) => x.key.fieldName === "catPlanRemoved" && x.data.submitValue).map((x) => x.key.linkingId);

        const getData = arrayToSentence(
          fields.filter(
            (filteredField) => filteredField.key.fieldName === field.key.fieldName
              && !linkingIdsToIgnore?.includes(filteredField.key.linkingId),
          ).map((mappedField) => mappedField.data.displayValue) as string[],
        );
        data = getData ? `${getData}` : undefined;
      } else {
        const getData = arrayToSentence(
          fields.filter(
            (filteredField) => filteredField.key.fieldName === field.key.fieldName,
          ).map((mappedField) => mappedField.data.displayValue) as string[],
        );
        data = getData ? `${getData}` : undefined;
      }
      if (data) {
        displayData[`$${field.key.fieldName}`] = data;
      }
    });
  }

  const transformedData = getTransformedFlowFieldValues(fields, linkingId, interpolationBehavior);
  Object.entries(transformedData).forEach(([ key, data ]) => {
    displayData[`$${key}`] = data;
  });

  return displayData;
};

export const getFlowProduct = (slug: string): KatKinProduct => {
  const currentFlow = useNewFormsServiceStore.getState()?.flows.find((flow) => flow.id === slug);
  return currentFlow?.product || "UNKNOWN";
};

export const getFlowFromId = (id: string): NewStoredFlow | undefined => useNewFormsServiceStore.getState()
  ?.flows.find(
    (flow) => flow.id === id,
  );

const hasUserConfirmedTheyAreOver18 = (value: FieldValue | FieldValue[]): ValidationResponse => {
  if (typeof value === "boolean" && value === true) {
    return {
      response: "SUCCESS",
    };
  }
  return { response: "ERROR", errorMessage: "User must be 18 or over" };
};

const postcodeValidator = (value: FieldValue | FieldValue[]): ValidationResponse => {
  if (typeof value === "string") {
    const result = postcodeCheck(value);
    if (result.errorType) {
      return {
        response: "FAILURE",
        errorMessage: result.message,
      };
    }
    return {
      response: "SUCCESS",
    };
  }
  return { response: "ERROR", errorMessage: "Error validating postcode" };
};

const addressLineHasValue = (line?: string) => !!line?.length && line?.length <= 255;
const addressObjectValidator = (address: AddressFieldValue, identifier: string)
: ValidationResponse | ValidationResponse[] => {
  const errors: ValidationResponse[] = [];
  if (identifier !== "billing") {
    const phoneNumber = address.phone as string;
    const result = isValidPhoneNumber(phoneNumber, "GB");
    if (!result) {
      errors.push({
        response: "FAILURE",
        errorMessage: "We require a valid phone number in case we need to contact you regarding your delivery.",
        subFieldName: "phone",
      });
    }
  }

  if (!addressLineHasValue(address.line1)) {
    errors.push({
      response: "FAILURE",
      errorMessage: identifier === "billing" ? "We need an address line to bill you." : "We need an address line to deliver to you.",
      subFieldName: `${identifier}-line1`,
    });
  }

  if (!addressLineHasValue(address.city)) {
    errors.push({
      response: "FAILURE",
      errorMessage: identifier === "billing" ? "We need a town or city to bill you." : "We need an town or city to deliver to you.",
      subFieldName: `${identifier}-city`,
    });
  }

  if (!addressLineHasValue(address.country)) {
    errors.push({
      response: "FAILURE",
      errorMessage: identifier === "billing" ? "We need a country to bill you." : "We need an country to deliver to you.",
      subFieldName: `${identifier}-country`,
    });
  }

  if (identifier === "billing") {
    if (!addressLineHasValue(address.postcode)) {
      errors.push({
        response: "FAILURE",
        errorMessage: "We need a postcode to bill you.",
        subFieldName: `${identifier}-postcode`,
      });
    }
  } else {
    const postCodeResp = postcodeCheck(address.postcode);
    if (postCodeResp?.errorType) {
      errors.push({
        response: "FAILURE",
        errorMessage: "We need valid UK postcode to deliver to you.",
        subFieldName: `${identifier}-postcode`,
      });
    }
  }

  if (errors.length) {
    return errors;
  }
  return {
    response: "SUCCESS",
  };

};

const addressValidator = (value: FieldValue | FieldValue[]): ValidationResponse | ValidationResponse[] => {
  const addressErrors = [];
  if (typeof value === "object" && "shippingAddress" in value) {
    if (typeof value.shippingAddress === "object" && "phone" in value.shippingAddress) {
      const resp = addressObjectValidator(value.shippingAddress, "shipping");
      if (Array.isArray(resp)) {
        addressErrors.push(...resp);
      }
    }
  }
  if (typeof value === "object" && "billingAddress" in value) {
    if (typeof value.billingAddress === "object" && "phone" in value.billingAddress) {

      const resp = addressObjectValidator(value.billingAddress, "billing");
      if (Array.isArray(resp)) {
        addressErrors.push(...resp);
      }
    }
  }
  if (addressErrors.length && addressErrors.length > 0) {
    return addressErrors;
  }
  return { response: "SUCCESS" };
};

const recipeQuantityValidator = (
  value: FieldValue | FieldValue[],
  flowSlug: string,
  linkingId: string,
): ValidationResponse => {
  const requiredQuantity = +(getFieldValue(flowSlug, "totalRecipesRequired", linkingId)?.data?.submitValue ?? 0);
  if (requiredQuantity && typeof requiredQuantity === "number") {
    const recipes = (value as { [recipe: string]: number });
    let total = 0;
    Object.keys(recipes).forEach((rec: string) => {
      total += recipes[rec];
    });
    if (total !== requiredQuantity) {
      return {
        response: "FAILURE",
        errorMessage: "Wrong number of selected recipes",
      };
    }
    return {
      response: "SUCCESS",
    };
  }
  return { response: "ERROR", errorMessage: "Error validating recipe quantities" };
};

const gccfKittenNumberValidator = (
  value: FieldValue | FieldValue[],
  flowSlug: string,
  linkingId: string,
): ValidationResponse => {
  const getGccfNumber = getFieldValue(flowSlug, "gccfKittenNumber", linkingId)?.data.submitValue as string;
  const currentGccfKittenNumbers = getFieldValues(flowSlug, "gccfKittenNumber").filter((x) => x.key.linkingId !== linkingId);

  if (currentGccfKittenNumbers && currentGccfKittenNumbers.length) {
    if (currentGccfKittenNumbers.some(
      (number) => (number.data.submitValue as string).trim().toLowerCase() === (value as string).trim().toLowerCase(),
    )) {
      return {
        response: "FAILURE",
        errorMessage: "GCCF numbers must be unique.",
      };
    }
  }
  // This might seem like a bonkers validation rule but this was the rule we were asked to implement
  // So in about 12000 GCCF kittens time when this breaks I apologise.
  // https://app.shortcut.com/katkin/story/11116/copy-edits-and-additional-fe-validation-to-the-schedule-a-box-flow
  if (getGccfNumber?.length !== 7 || !getGccfNumber?.startsWith("11") || +getGccfNumber <= 1183000) {
    return {
      response: "FAILURE",
      errorMessage: "Invalid GCCF number.",
    };
  }
  return {
    response: "SUCCESS",
  };
};

const catNameValidator = (
  value: FieldValue | FieldValue[],
  flowSlug: string,
  linkingId: string,
): ValidationResponse => {
  const currentCatNames = getFieldValues(flowSlug, "catName").filter((x) => x.key.linkingId !== linkingId);

  if (currentCatNames && currentCatNames.length) {
    if (currentCatNames.some(
      (cat) => (cat.data.submitValue as string).trim().toLowerCase() === (value as string).trim().toLowerCase(),
    )) {
      return {
        response: "FAILURE",
        errorMessage: "Cat names must be unique.",
      };
    }
  }
  return {
    response: "SUCCESS",
  };
};

const catWeightValidator = (
  value: FieldValue,
): ValidationResponse => {
  if (+value <= 0) {
    return {
      response: "FAILURE",
      errorMessage: "Cat weight must be more than 0kg.",
    };
  }
  return {
    response: "SUCCESS",
  };
};

const conditionalValidator = (
  fieldName: string,
  conditionalFieldName: string,
  conditionValue: string,
  flowSlug: string,
  linkingId: string,
  isActuallyRequiredIfShown: boolean,
  exclusionary = false,
) => {
  const storedConditionValue = getFieldValue(flowSlug, conditionalFieldName, linkingId)?.data?.submitValue as string; // conditionalFieldName = HasAllergies
  if (isActuallyRequiredIfShown &&
    (exclusionary
      ? storedConditionValue.toString().toLowerCase() !== conditionValue.toLowerCase()
      : storedConditionValue.toString().toLowerCase() === conditionValue.toLowerCase())) {
    // Do we then have a value for this conditional field in the store?
    const storedConditionalValue = getFieldValue(flowSlug, fieldName, linkingId)?.data?.submitValue; // storedConditionalValue = list of allergies
    return Array.isArray(storedConditionalValue) ? storedConditionalValue.length > 0 : !!storedConditionalValue;
  }
  return true;
};

interface FieldValidator {
  fieldName: string;
  validate: (
    value: (FieldValue) | (FieldValue | FieldValue[]),
    flowSlug?: string,
    linkingId?: string,
  ) => ValidationResponse | ValidationResponse[];
}

const fieldValidators: FieldValidator[] = [
  {
    fieldName: "postcode",
    validate: (value) => postcodeValidator(value),
  },
  {
    fieldName: "gccfKittenNumber",
    validate: (value, flowSlug, linkingId) => gccfKittenNumberValidator(value, flowSlug || "", linkingId || ""),
  },
  {
    fieldName: "userOver18",
    validate: (value) => hasUserConfirmedTheyAreOver18(value),
  },
  {
    fieldName: "address",
    validate: (value) => addressValidator(value),
  },
  {
    fieldName: "recipesWithQuantities",
    validate: (value, flowSlug, linkingId) => recipeQuantityValidator(value, flowSlug || "", linkingId || ""),
  },
  {
    fieldName: "catName",
    validate: (value, flowSlug, linkingId) => catNameValidator(value, flowSlug || "", linkingId || ""),
  },
  {
    fieldName: "catWeight",
    validate: (value) => catWeightValidator(value as FieldValue),
  },
];

export const isFieldValid = (
  flowSlug: string,
  linkingId: string,
  fieldName: FieldName,
  isRequired: boolean,
): boolean => {
  if (!isRequired) return true;
  const fieldValue = getFieldValue(flowSlug, fieldName, linkingId)?.data.submitValue;
  const errors = useNewFormsServiceStore.getState().getFlowFieldErrors(flowSlug, fieldName, linkingId);

  if (!fieldValue || !!errors?.length) return false;

  const specialistValidator = fieldValidators.find(({ fieldName: mappedFieldName }) => mappedFieldName === fieldName);

  if (!specialistValidator && fieldValue) {
    return true;
  }

  const validation = specialistValidator?.validate(fieldValue);

  if (Array.isArray(validation)) {
    let isValid = true;
    validation.forEach((mappedValidation) => {
      if ([ "FAILURE", "ERROR" ].includes(mappedValidation.response)) {
        isValid = false;
      }
    });
    return isValid;
  }

  return validation?.response === "SUCCESS";
};

export const validateSection = (
  flowSlug: string,
  section: SectionComponent,
  logger?: Logger,
) => {
  if (!section) {
    return false;
  }
  const specialFields = [
    "forms_CTA",
    "summary",
    "future_summary",
    "form_icon_text_list",
    "payment_details",
    "cat_summary",
    "cat_summary_b",
    "pick_recipes",
    "form_add_product_card_list",
    "review_details",
    "review_form_details",
    "quick_checkout",
    "form_add_product",
    "survey_cancellation",
    "section_form_cancellation_router",
    "pick_recipes_highlight_recipes_test",
    "cat_summary_highlight_recipes_test",
  ];
  const fieldsWithRepeat = (section?.fieldsets.filter(
    (fieldset) => !!isFieldset(fieldset),
  ) as FieldsetComponent[]).map((fs) => ({ fields: fs.fields, repeatFrom: fs.repeat_from }))
    .flatMap((f) => f.fields.map((fi) => ({ field: fi, repeatFrom: f.repeatFrom })));
  const requiredFieldsNames = fieldsWithRepeat.filter((field) => !field.field.optional).filter(
    (f) => !specialFields.includes(f.field.component),
  ).map(
    (f) => ({ name: f.field.name, quantity: getRepeatedFieldsArray(flowSlug, f.repeatFrom).length }),
  );

  const currentFlow = useNewFormsServiceStore.getState()?.flows.find((fl) => fl.id === flowSlug);
  const conditionalFields = (section?.fieldsets.filter(
    (fieldset) => !!isFieldset(fieldset),
  ) as FieldsetComponent[]).map((fs) => ({ fields: fs.fields }))
    .flatMap((f) => f.fields.filter((fi) => (fi.use_conditional_logic && !fi.optional)));
  const linkingIdsToCheckConds = currentFlow?.fields?.filter(
    (fv) => conditionalFields.some((cf) => cf.conditional_name === fv.key.fieldName && !cf.optional),
  ) || [];
  const missingConditions = conditionalFields.filter(
    (cf) => cf.conditional_name && cf.conditional_value
    && linkingIdsToCheckConds.some((lid) => lid.key.fieldName === cf.conditional_name
    && !conditionalValidator(cf.name, cf.conditional_name || "", cf.conditional_value || "", flowSlug, lid.key.linkingId || "0", !cf.optional, cf.exclusionary)),
  ).length > 0;

  const missingFields = requiredFieldsNames
    .some((rf) => !conditionalFields.some((cf) => cf.name === rf.name) && (
      currentFlow?.fields?.filter((f) => f.key.fieldName === rf.name
      && f.data.submitValue).length !== rf.quantity));

  let validationErrors = false;
  // We now care about the special fields again
  const fieldsApplicableToValidators = [ "recipesWithQuantities", ...requiredFieldsNames.map((rf) => rf.name) ];
  fieldValidators.forEach((fieldValidator) => {
    console.log("validating section!");
    if (fieldsApplicableToValidators.some((f) => f === fieldValidator.fieldName)) {
      const fieldsToValidate = currentFlow?.fields?.filter((f) => f.key.fieldName === fieldValidator.fieldName);
      fieldsToValidate?.forEach((fieldToValidate) => {
        if (fieldToValidate && fieldToValidate.data && fieldToValidate.data.submitValue) {
          const validationResp = fieldValidator.validate(
            fieldToValidate.data.submitValue,
            flowSlug,
            fieldToValidate.key.linkingId,
          );
          if (Array.isArray(validationResp)) {
            validationResp.forEach((z) => {
              if (z.response === "ERROR" || z.response === "FAILURE") {
                validationErrors = true;
                const currentErrorForField = useNewFormsServiceStore.getState()?.errors.find(
                  (e) => e.id === flowSlug,
                )?.fieldErrors?.find(
                  (fe) => fe.fieldName === fieldValidator.fieldName && fe.linkingId === fieldToValidate.key.linkingId
                    && fe.subFieldName === z.subFieldName,
                );
                if (!currentErrorForField || currentErrorForField?.error?.message !== z.errorMessage) {
                  console.log(
                    "Setting flow field error",
                    flowSlug,
                    fieldValidator.fieldName,
                    z.errorMessage,
                    fieldToValidate.key.linkingId,
                    z.subFieldName,
                  );
                  useNewFormsServiceStore.getState()?.setFlowFieldError(
                    flowSlug,
                    fieldValidator.fieldName,
                    { message: z.errorMessage },
                    fieldToValidate.key.linkingId,
                    z.subFieldName,
                  );
                }
              }
            });
            // Figure out which errors to now remove
            const errorsToRemove = useNewFormsServiceStore.getState()?.errors.filter(
              (e) => e.id === flowSlug,
            )?.flatMap((x) => x.fieldErrors?.filter(
              (fe) => fe.fieldName === fieldValidator.fieldName && fe.linkingId === fieldToValidate.key.linkingId
                && !validationResp.some((f) => f.subFieldName === fe.subFieldName),
            ));
            errorsToRemove.forEach((err) => {
              if (err) {
                useNewFormsServiceStore.getState()?.removeFlowFieldError(
                  flowSlug,
                  err.fieldName,
                  fieldToValidate.key.linkingId,
                  err.subFieldName,
                );
              }
            });

          } else if (validationResp.response === "ERROR" || validationResp.response === "FAILURE") {
            validationErrors = true;
            if (logger) {
              logger.warn(
                `Validation for field ${fieldToValidate.key.fieldName} failed with reason ${validationResp.response}:${validationResp.errorMessage}`,
                {
                  eventName: "FieldValidationFailed",
                  eventType: "FieldValidation",
                  validationResp,
                  flowSlug,
                  fieldToValidate,
                },
              );
            }
            const currentErrorForField = useNewFormsServiceStore.getState()?.errors.find(
              (e) => e.id === flowSlug,
            )?.fieldErrors?.find(
              (fe) => fe.fieldName === fieldValidator.fieldName && fe.linkingId === fieldToValidate.key.linkingId
                && fe.subFieldName === validationResp.subFieldName,
            );
            if (!currentErrorForField || currentErrorForField?.error?.message !== validationResp.errorMessage) {
              console.log(
                "Setting flow field error",
                flowSlug,
                fieldValidator.fieldName,
                validationResp.errorMessage,
                fieldToValidate.key.linkingId,
                validationResp.subFieldName,
              );
              useNewFormsServiceStore.getState()?.setFlowFieldError(
                flowSlug,
                fieldValidator.fieldName,
                { message: validationResp.errorMessage },
                fieldToValidate.key.linkingId,
                validationResp.subFieldName,
              );
            }
          } else if (useNewFormsServiceStore.getState()?.errors.find(
            (e) => e.id === flowSlug,
          )?.fieldErrors?.find(
            (fe) => fe.fieldName === fieldValidator.fieldName && fe.linkingId === fieldToValidate.key.linkingId,
          )) {
            console.log("Removing flow field error(s).");
            const errors = useNewFormsServiceStore.getState()?.errors.filter(
              (e) => e.id === flowSlug,
            )?.flatMap((x) => x.fieldErrors?.filter(
              (fe) => fe.fieldName === fieldValidator.fieldName && fe.linkingId === fieldToValidate.key.linkingId,
            ));

            errors.forEach((err) => {
              if (err) {
                useNewFormsServiceStore.getState()?.removeFlowFieldError(
                  flowSlug,
                  err.fieldName,
                  fieldToValidate.key.linkingId,
                  err.subFieldName,
                );
              }
            });

          }
        }
      });
    }
  });
  return !(missingFields || validationErrors || missingConditions);
};

const getSelectedOptions = (field: OptionsBasedField) => {
  if (field.value) {
    const availOptions = field.options as OptionType[];
    if (Array.isArray(field.value)) {
      const valuesSelected = field.value as (string[] | Dayjs[] | number[]);
      return availOptions.filter((opt) => valuesSelected.some((value) => value === opt.value));
    }
    return availOptions.filter((opt: OptionType) => opt.value === field.value)[0];
  }
  return null;
};

interface NewStoredFlow {
  id: string; // slug
  product: KatKinProduct;
  fieldsets: FieldsetMap[];
  fields?: FieldMap[];
}

interface NewStoredFlowError {
  id: string; // slug
  fieldErrors?: FlowFieldError[];
  flowErrors?: string[];
}

interface FlowFieldError {
  fieldName: string;
  linkingId: string;
  error?: BaseFieldComponentError;
  subFieldName?: string; // Currently only for address validation
}

export interface FieldData {
  submitValue?: FieldValue | FieldValue[];
  displayValue?: FieldValue | FieldValue[];
  error?: BaseFieldComponentError;
  additionalData?: AdditionalFieldData | AdditionalFieldData[];
}

interface IUseFormServiceStoreNew {
  flows: NewStoredFlow[];
  errors: NewStoredFlowError[];
  setFlowFieldsetData(
    flowId: string,
    fieldsetId: string,
    data: FieldsetData,
    linkingId?: string
  ): void;
  setFlowFieldValue(
    flowId: string,
    fieldId: string,
    value: FieldData,
    linkingId?: string): void;
  setFlowFieldError(
    flowId: string,
    fieldId: string,
    error: BaseFieldComponentError,
    linkingId?: string,
    subFieldName?: string,
  ): void;
  setFlowError(
    flowId: string,
    error: string,
  ): void;
  clearFlowErrors(
    flowId: string,
  ): void;
  removeFlowFieldError(flowId: string, fieldId: string, linkingId?: string, subFieldName?: string): void;
  removeFlowFields(flowId: string): void,
  removeFlowField(flowId: string, fieldId: string, linkingId?: string): void;
  getFlowFieldErrors(
    flowId: string,
    fieldId: string,
    linkingId?: string,
    subFieldName?: string,
  ): FlowFieldError[] | undefined;
  getFlowErrors(
    flowId: string,
  ): string[] | undefined;
  initialiseStore(newFlow: FlowDataStructureResponse): void;
  isFormServiceStoreInitialised: boolean;
  getActiveFieldValue(flowId: string, fieldId: string, linkingId?: string): FieldMap | undefined;
  setFlowCatData(flowId: string, cat: ICatExtraDetails): void;
}

const mapFieldToFieldData = (field: FieldComponent): FieldData | undefined => {
  if (field.value) {
    if (!isOptionsField(field)) {
      return {
        submitValue: field.value,
        displayValue: field.value,
        error: field.error,
      };
    }
    const selectedOptions = getSelectedOptions(field);
    if (selectedOptions) {
      if (Array.isArray(selectedOptions)) {
        return {
          submitValue: selectedOptions.map((opt) => opt.value),
          displayValue: selectedOptions.map((opt) => opt.title),
          error: field.error,
        };
      }
      return {
        submitValue: selectedOptions?.value,
        displayValue: selectedOptions?.title,
        error: field.error,
      };
    }
  }
  return undefined;
};
const isOptionsField = (field: FieldComponent):
  field is OptionsBasedField => field.component === "radio_select" || field.component === "select" || field.component === "checkbox_group" || field.component === "step_counter_select";

export const useNewFormsServiceStore = create<IUseFormServiceStoreNew>()(persist((set, get) => ({
  flows: [] as NewStoredFlow[],
  errors: [] as NewStoredFlowError[],
  setFlowCatData: async (flowId, cat) => set((state) => {
    const flowsToBeUpdated = state.flows;
    const flowToBeUpdated = flowsToBeUpdated.find((f) => f.id === flowId);

    const createUpdateField = (fieldName: FieldName, submitValue: string, displayValue: string) => {
      const existingFieldNameValue = flowToBeUpdated?.fields?.find((field) => field.key.fieldName === fieldName && field.key.linkingId === "0");
      if (existingFieldNameValue) {
        existingFieldNameValue.data = {
          displayValue,
          submitValue,
        };
      } else {
        flowToBeUpdated?.fields?.push({
          key: {
            fieldName,
            linkingId: "0",
          },
          data: {
            submitValue,
            displayValue,
          },
        });
      }
    };

    createUpdateField("catName", cat.cat.name, cat.cat.name);
    createUpdateField("catSex", cat.cat.gender, cat.cat.gender === "BOY" ? "Boy" : "Girl");
    createUpdateField("catId", cat.cat.id.value, cat.cat.id.value);
    createUpdateField("freshMealPlanId", `${cat.freshMealPlanId}`, `${cat.freshMealPlanId}`);

    return { flows: flowsToBeUpdated };
  }),
  getFieldValue: (flowId: string, fieldName: string, linkingId?: string) => {
    const flow = useNewFormsServiceStore.getState().flows.find((f) => f.id === flowId);
    if (!linkingId) {
      return flow?.fields?.find((f) => f.key.fieldName === fieldName);
    }
    return flow?.fields?.find((f) => f.key.fieldName === fieldName && f.key.linkingId === linkingId);
  },
  isFormServiceStoreInitialised: false as boolean, // types are weird
  initialiseStore: (newFlow) => set((state) => {
    const activeFlow = state.flows.find((flow) => flow.id === newFlow.slug);
    if (activeFlow) {
      const dateFields = activeFlow.fields?.filter((field) => [ "deliveryDate", "freshDeliveryDate" ].includes(field.key.fieldName));

      dateFields?.forEach((dateField) => {
        if (dayjs(dateField.data.submitValue as Dayjs).isBefore(dayjs().add(2, "day"))) {
          console.log("Removing date field.");
          get().removeFlowField(newFlow.slug, dateField.key.fieldName, dateField.key.linkingId);
        }
      });
      return { flows: state.flows };
    }
    const fieldsets: FieldsetComponent[] = newFlow.mappedFlow.forms
      .flatMap((form) => form.content.sections)
      .flatMap((section) => section.fieldsets.filter((fieldset) => !!isFieldset(fieldset))) as FieldsetComponent[];

    const fieldsetsWithState = fieldsets
      .filter((fieldset) => (!!fieldset.collapsable_id));

    const fieldsetMap: FieldsetMap[] = [];

    fieldsetsWithState.forEach((fieldset) => {
      const data = {
        state: fieldset.collapsable_type === "togglable" ? fieldset.togglable_default_state || "open" : fieldset.collapsable_type,
      };
      fieldsetMap.push({
        key: {
          fieldsetId: fieldset.collapsable_id as string,
          linkingId: "0",
        },
        data,
      });
    });

    const fieldsWithValues = fieldsets
      .flatMap((fieldset) => (fieldset as FieldsetComponent).fields)
      .filter((field) => !!field)
      .filter((field) => field.value || (isOptionsField(field) && getSelectedOptions(field)));
    const fieldMap: FieldMap[] = [];
    fieldsWithValues.forEach((f) => {
      const mappedFieldData = mapFieldToFieldData(f);
      if (mappedFieldData) {
        if (fieldMap.some((fm) => fm.key.fieldName === f.name)) {
          // Shouldn't happen
          console.error("Field already has value, shouldn't occur.", f.name);
        } else {
          fieldMap.push({
            key: {
              fieldName: f.name as FieldName,
              linkingId: "0",
            },
            data: mappedFieldData,
          });
        }
      }
    });

    const mappedFlow: NewStoredFlow = {
      id: newFlow.slug,
      fields: fieldMap,
      fieldsets: fieldsetMap,
      product: newFlow.mappedFlow.product || "UNKNOWN",
    };

    return {
      flows: [ ...state.flows, mappedFlow ],
      isFormServiceStoreInitialised: true,
    };
  }),
  setFlowFieldsetData: (flowSlug, fieldsetId, data, linkingId) => set((state) => {
    const flowsToBeUpdated = state.flows;
    const flowToBeUpdated = flowsToBeUpdated.find((f) => f.id === flowSlug);
    if (flowToBeUpdated) {
      if (linkingId) {
        if (flowToBeUpdated.fieldsets?.some((f) => f.key.fieldsetId === fieldsetId && f.key.linkingId === linkingId)) {
          const fieldsetToUpdate = flowToBeUpdated
            .fieldsets?.find((f) => f.key.fieldsetId === fieldsetId && f.key.linkingId === linkingId);
          if (fieldsetToUpdate) {
            fieldsetToUpdate.data = data;
          }
        } else {
          flowToBeUpdated.fieldsets?.push({
            key: {
              fieldsetId: fieldsetId as FieldName,
              linkingId,
            },
            data,
          });
        }
      } else if (flowToBeUpdated.fieldsets?.some((f) => f.key.fieldsetId === fieldsetId)) {
        const fieldToUpdate = flowToBeUpdated.fieldsets?.find((f) => f.key.fieldsetId === fieldsetId);
        if (fieldToUpdate) {
          fieldToUpdate.data = data;
        }
      } else {
        flowToBeUpdated.fieldsets?.push({
          key: {
            fieldsetId: fieldsetId as FieldName,
            linkingId,
          },
          data,
        });
      }
    }
    console.log(flowToBeUpdated);
    return { flows: flowsToBeUpdated };
  }),
  setFlowFieldValue: (flowSlug, fieldName, newValue, linkingId) => set((state) => {
    const flowsToBeUpdated = state.flows;
    const flowToBeUpdated = flowsToBeUpdated.find((f) => f.id === flowSlug);
    if (flowToBeUpdated) {
      if (linkingId) {
        if (flowToBeUpdated.fields?.some((f) => f.key.fieldName === fieldName && f.key.linkingId === linkingId)) {
          const fieldToUpdate = flowToBeUpdated
            .fields?.find((f) => f.key.fieldName === fieldName && f.key.linkingId === linkingId);
          if (fieldToUpdate) {
            fieldToUpdate.data = newValue;
          }
        } else {
          flowToBeUpdated.fields?.push({
            key: {
              fieldName: fieldName as FieldName,
              linkingId,
            },
            data: newValue,
          });
        }
      } else if (flowToBeUpdated.fields?.some((f) => f.key.fieldName === fieldName)) {
        const fieldToUpdate = flowToBeUpdated.fields?.find((f) => f.key.fieldName === fieldName);
        if (fieldToUpdate) {
          fieldToUpdate.data = newValue;
        }
      } else {
        flowToBeUpdated.fields?.push({
          key: {
            fieldName: fieldName as FieldName,
            linkingId,
          },
          data: newValue,
        });
      }
    }
    return { flows: flowsToBeUpdated };
  }),
  clearFlowErrors: (flowId) => set((state) => {
    const errorsToBeUpdated = state.errors;
    const errorToBeUpdated = errorsToBeUpdated.filter((f) => f.id === flowId)?.[0];
    if (errorToBeUpdated) {
      errorToBeUpdated.flowErrors = [];
    }
    return { ...state, errors: errorsToBeUpdated };
  }),
  setFlowError: (flowId, error) => set((state) => {
    const errorsToBeUpdated = state.errors;
    const errorToBeUpdated = errorsToBeUpdated.filter((f) => f.id === flowId)?.[0];
    if (errorToBeUpdated) {
      errorToBeUpdated.flowErrors?.push(error);
    }
    return { ...state, errors: errorsToBeUpdated };
  }),
  setFlowFieldError: (flowId, fieldName, error, linkingId, subFieldName) => set((state) => {
    const flowsToBeUpdated = state.errors;
    const flowToBeUpdated = flowsToBeUpdated.filter((f) => f.id === flowId)?.[0];
    if (flowToBeUpdated) {
      if (linkingId) {
        if (flowToBeUpdated.fieldErrors?.some((f) => f.fieldName === fieldName
          && f.linkingId === linkingId && subFieldName === f.subFieldName)) {

          const fieldToUpdate = flowToBeUpdated
            .fieldErrors?.find((f) => f.fieldName === fieldName
              && f.linkingId === linkingId && subFieldName === f.subFieldName);
          if (fieldToUpdate) {
            fieldToUpdate.error = error;
          }
        } else {
          flowToBeUpdated.fieldErrors?.push({
            fieldName,
            linkingId: linkingId ?? "",
            error,
            subFieldName,
          });
        }
      } else if (flowToBeUpdated.fieldErrors?.some((f) => f.fieldName === fieldName
        && subFieldName === f.subFieldName)) {
        const fieldToUpdate = flowToBeUpdated.fieldErrors?.find((f) => f.fieldName === fieldName
          && subFieldName === f.subFieldName);
        if (fieldToUpdate) {
          fieldToUpdate.error = error;
        }
      } else {
        flowToBeUpdated.fieldErrors?.push({
          fieldName,
          linkingId: linkingId ?? "",
          error,
          subFieldName,
        });
      }
    } else {
      return {
        ...state,
        errors: [ {
          id: flowId,
          fieldErrors: [ {
            fieldName,
            linkingId: linkingId ?? "",
            error,
            subFieldName,
          } ],
        } ],
      };
    }
    return { ...state, errors: flowsToBeUpdated };
  }),
  removeFlowFieldError: (flowId, fieldName, linkingId, subFieldName) => set((state) => {
    const flowsToBeUpdated = state.errors;
    const flowToBeUpdated = flowsToBeUpdated.filter((f) => f.id === flowId)?.[0];
    if (flowToBeUpdated) {
      if (linkingId) {
        if (flowToBeUpdated.fieldErrors?.some((f) => f.fieldName === fieldName && f.linkingId === linkingId
          && subFieldName === f.subFieldName)) {
          const fieldsToUpdate = flowToBeUpdated
            .fieldErrors?.filter((f) => !(f.fieldName === fieldName && f.linkingId === linkingId
                && subFieldName === f.subFieldName));
          return {
            errors: [ {
              id: flowId,
              fieldErrors: fieldsToUpdate,
            } ],
          };
        }
      } else if (flowToBeUpdated.fieldErrors?.some((f) => f.fieldName === fieldName
        && subFieldName === f.subFieldName)) {
        const fieldToUpdate = flowToBeUpdated.fieldErrors?.filter((f) => f.fieldName !== fieldName
          && subFieldName !== f.subFieldName);
        if (fieldToUpdate) {
          return {
            errors: [ {
              id: flowId,
              fieldErrors: fieldToUpdate,
            } ],
          };
        }
      }
    }
    return { errors: flowsToBeUpdated };

  }),
  getFlowErrors: (
    flowId: string,
  ) => get().errors.find(
    (flow) => flow.id === flowId,
  )?.flowErrors,
  getFlowFieldErrors: (
    flowId: string,
    fieldId: string,
    linkingId = "0",
    subFieldName?: string,
  ) => get().errors.find(
    (flow) => flow.id === flowId,
  )?.fieldErrors?.filter(
    (field) => field.fieldName === fieldId && (subFieldName ? field.subFieldName === subFieldName : true) && [ linkingId, "" ].includes(field.linkingId),
  ),
  removeFlowField: (flowId, fieldName, linkingId) => set((state) => {
    const flowsToBeUpdated = state.flows;
    const flowToBeUpdated = flowsToBeUpdated.filter((f) => f.id === flowId)?.[0];
    if (flowToBeUpdated) {
      if (linkingId) {
        flowToBeUpdated.fields = flowToBeUpdated.fields?.filter((f) => f.key.fieldName !== fieldName
          || (f.key.fieldName === fieldName && f.key.linkingId !== linkingId));
      } else {
        flowToBeUpdated.fields = flowToBeUpdated.fields?.filter((f) => f.key.fieldName !== fieldName);
      }
    }
    return { flows: flowsToBeUpdated };
  }),
  removeFlowFields: (flowId) => set((state) => {
    const flowsToBeUpdated = state.flows;
    const flowToBeUpdated = flowsToBeUpdated.find((f) => f.id === flowId);
    if (flowToBeUpdated) {
      flowToBeUpdated.fields = [];
    }
    return { flows: flowsToBeUpdated };
  }),
  getActiveFieldValue: (flowId: string, fieldName: string, linkingId?: string) => {
    const flow = get().flows.find((f) => f.id === flowId);
    if (!linkingId) {
      return flow?.fields?.find((f) => f.key.fieldName === fieldName);
    }
    return flow?.fields?.find(
      (f) => f.key.fieldName === fieldName && f.key.linkingId === linkingId,
    ) as FieldMap | undefined;
  },
}), { name: "forms-store" }));

export default useNewFormsServiceStore;
